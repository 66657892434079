import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'

// fetch information about blotters dashboard
const fetchDashboard = async () => apiResultWrapper.apiResult(await http.get('/api/v1/mndb-tool-terminal/'))
const chartForId = async (id) => apiResultWrapper.apiResult(await http.get(`/api/v1/mndb-tool-terminal/${id}`))

export default {
  fetchDashboard,
  chartForId
}
