<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        style="height: 2px;"
      >
        <v-progress-linear
          height="2"
          indeterminate
          :active="loading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="text-center">
          <span
            style="font-size: 1rem; "
            class="text-uppercase"
          >Last Calculation Update:</span> <br>
          <span style="font-weight: 600; font-size: 1.4rem">{{ lastUpdateTimeOfData }}</span> <br>
          <span style="font-weight: 400; font-size: 1.0rem">{{ lastUpdateDateOfData }}</span>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="text-center">
          <span
            style="font-size: 1rem; "
            class="text-uppercase"
          >Fil Uploads:</span> <br>
          <span
            v-for="item in uploadFilesInfo"
            :key="item.type"
            style="font-weight: 400; font-size: 0.85rem; display: block"
          >
            {{ item.type }} : {{ item.time }}
          </span>
        </div>
      </v-col>
      <v-col
        v-if="error"
        cols="12"
      >
        <div
          class="text-center"
          style="color: red"
        >
          {{ error }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="text-center">
          <span>Hots</span>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="text-center">
          <span>Colds</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="hotStocks"
          :disable="loading"
          :search="filterHots"
          hide-default-footer
          disable-pagination
          loading-text="Loading... Please wait"
          class="elevation-1 row-pointer overflow-y-auto hot-cold"
          fixed-header
          height="calc(100vh - 230px)"
          @click:row="clickRow"
        >
          <template v-slot:top>
            <v-text-field
              v-model="filterHots"
              prepend-icon="mdi-magnify"
              label="Search symbol"
              clearable
              single-line
              class="mx-4"
            />
          </template>
          <template v-slot:item.pricePot="{item}">
            <td class="text-center" :style="'font-weight:400;' + (item.pricePot > 0 ? 'color: darkgreen; background-color: #00800022;' : 'color: red; background-color: #ff000022;')">
              {{item.pricePot}}
            </td>
          </template>
          <template v-slot:item.yieldPot="{item}">
            <td class="text-center" :style="'font-weight:400;' + (item.yieldPot > 0 ? 'color: darkgreen; background-color: #00800022;' : 'color: red; background-color: #ff000022;')">
              {{item.yieldPot}}
            </td>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
        <div>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="coldStocks"
            :disable="loading"
            :search="filterColds"
            hide-default-footer
            disable-pagination
            fixed-header
            height="calc(100vh - 230px)"
            loading-text="Loading... Please wait"
            class="elevation-1 row-pointer overflow-y-auto hot-cold"
            @click:row="clickRow"
          >
            <template v-slot:top>
              <v-text-field
                v-model="filterColds"
                prepend-icon="mdi-magnify"
                label="Search symbol"
                clearable
                single-line
                class="mx-4"
              />
            </template>
            <template v-slot:item.pricePot="{item}">
              <td class="text-center" :style="'font-weight:400;' + (item.pricePot > 0 ? 'color: darkgreen; background-color: #00800022;' : 'color: red; background-color: #ff000022;')">
                {{item.pricePot}}
              </td>
            </template>
            <template v-slot:item.yieldPot="{item}">
              <td class="text-center" :style="'font-weight:400;' + (item.yieldPot > 0 ? 'color: darkgreen; background-color: #00800022;' : 'color: red; background-color: #ff000022;')">
                {{item.yieldPot}}
              </td>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="previewChart"
        width="70%"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <div style="height: 70%; padding: 12px; background-color: white">
          <div style="background-color: #e7e7e7;">
            <vue-apex-charts
              id="chart"
              ref="chart"
              type="line"
              :options="chart.options"
              :series="chart.series"
              :height="chart.height"
            />
          </div>
          <div style="background-color: #e7e7e7;">
            <vue-apex-charts
              id="chart-delta"
              ref="chart-delta"
              type="line"
              :options="chartDelta.options"
              :series="chartDelta.series"
              :height="chartDelta.height"
            />
          </div>
        </div>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog
        v-model="chart.loading"
        hide-overlay
        persistent
        class="mt-0"
        width="300"
      >
        <v-card
          class="mt-0"
          color="primary"
        >
          <v-card-text>
            Chart loading ...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-1 mt-1"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import mndbToolService from '@/service/MndbToolService'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'MndbToolTerminal',
  components: {
    VueApexCharts
  },
  props: {
  },
  data: () => ({
    previewChart: false,
    chartDelta: {
      series: [{ name: 'delta', color: 'rgba(32,171,195,0.78)', data: [] }],
      options: {
        chart: {
          toolbar: {
            show: true
          },
          id: 'delta-chart'
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value).toFixed(2) + ' %'
            }
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function (val) {
              return new Date(val).toLocaleDateString('fr')
            }
          }
        },
        annotations: {
          xaxis: []
        }
      },
      loading: false,
      height: window.innerHeight - window.innerHeight * 0.66,
      colors: {
        colorBack: '#fff',
        colorGrid: '#eee',
        colorText: '#333'
      }
    },
    chart: {
      series: [{ name: 'groupAvg', data: [] }, { name: 'yield', data: [] }],
      seriesDelta: [{ name: 'delta', data: [] }],
      options: {
        chart: {
          id: 'average-delta-chart'
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number(value).toFixed(2) + ' %'
            }
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function (val) {
              return new Date(val).toLocaleDateString('fr')
            }
          }
        },
        annotations: {
          xaxis: []
        }
      },
      loading: false,
      height: window.innerHeight - window.innerHeight * 0.66,
      colors: {
        colorBack: '#fff',
        colorGrid: '#eee',
        colorText: '#333'
      }
    },
    headers: [
      { text: 'Symbol', value: 'symbol', align: 'center' },
      { text: 'Price Pot', value: 'pricePot', align: 'center' },
      { text: 'Price', value: 'price', align: 'center' },
      { text: 'Max Δ', value: 'max', align: 'center' },
      { text: 'Min Δ', value: 'min', align: 'center' },
      { text: 'Avg Δ', value: 'avg', align: 'center' },
      { text: 'YTW', value: 'ytw', align: 'center' },
      { text: 'Group Avg', value: 'groupAvg', align: 'center' },
      { text: 'Current Δ', value: 'currentDelta', align: 'center' },
      { text: 'Yield Pot', value: 'yieldPot', align: 'center' }
    ],
    filterColds: '',
    filterHots: '',
    sort: null,
    loading: false,
    error: null,
    lastTimeData: new Date().getTime(),
    lastUpdateDateOfData: '',
    lastUpdateTimeOfData: '',
    hotStocks: [],
    coldStocks: [],
    uploadFilesInfo: [],
    hotStocksSort: null,
    coldStocksSort: null,
    periodicCheck: null
  }),
  computed: {
    hostStocksSorted: () => {
      return []
    },
    coldStocksSorted: () => {
      return []
    },
    calcHeight () {
      return window.innerHeight - window.innerHeight * 0.66;
    },
  },
  beforeMount () {
    this.loadDashboardData()
    this.createTimer()
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  destroyed () {
    clearInterval(this.periodicCheck)
  },
  methods: {
    onResize (event) {
      this.chart.height = this.calcHeight()
      this.chartDelta.height = this.calcHeight()
    },
    async loadDashboardData () {
      this.loading = true
      const response = await mndbToolService.fetchDashboard()
      if (response.status === 200) {
        this.error = null
        this.lastTimeData = response.data.timestamp
        this.lastUpdateDateOfData = new Date(this.lastTimeData).toLocaleDateString('fr')
        this.lastUpdateTimeOfData = new Date(this.lastTimeData).toLocaleTimeString('fr')
        this.hotStocks = response.data.hots
        this.coldStocks = response.data.colds
        this.uploadFilesInfo = response.data.uploadFileInfoItems || []
      } else {
        this.error = response.error.message
      }
      this.loading = false
    },
    createTimer () {
      this.periodicCheck = setInterval(() => {
        this.loadDashboardData()
      }, 10000)
    },
    async clickRow (r) {
      this.chart.loading = true
      // set loader
      //
      const response = await mndbToolService.chartForId(r.symbol)
      if (response.status === 200) {
        this.error = null
        this.chart.series[0].data = response.data.groupAvg.map(i => {
          // return { x: new Date(i.timestamp + 60 * 60 * 1000 * 4).toLocaleDateString('fr'), y: i.value }
          return { x: i.timestamp + 60 * 60 * 1000 * 4, y: i.value }
        })
        this.chart.series[1].data = response.data.yieldToWorst.map(i => {
          // return { x: new Date(i.timestamp + 60 * 60 * 1000 * 4).toLocaleDateString('fr'), y: i.value }
          return { x: i.timestamp + 60 * 60 * 1000 * 4, y: i.value - 1 }
        })
        //
        let groupAvgs = this.chart.series[0].data;
        let yields = this.chart.series[1].data;
        let deltas = [];
        let len = groupAvgs.length;
        let len2 = yields.length;
        let min = 100;
        let max = -100;
        let delta = 0;
        let minIndex = -1;
        let maxIndex = -1;
        for (let i = 0; i < len && i < len2; i++) {
          delta = Math.abs(groupAvgs[i].y) - Math.abs(yields[i].y);
          deltas.push({x: groupAvgs[i].x, y: delta})
          if (min > delta) {
            min = delta;
            minIndex = i;
          }
          if (max < delta) {
            max = delta;
            maxIndex = i;
          }
        }

        let xaxis = [];
        if (minIndex > -1 && maxIndex > -1) {
          xaxis = [{
            x: groupAvgs[minIndex].x,
            strokeDashArray: 0,
            borderColor: '#7f6fb7',
            label: {
              borderColor: '#775DD0',
              style: {
                color: '#fff',
                background: '#775DD0',
              },
              text: `MIN (${(groupAvgs[minIndex].y - yields[minIndex].y).toFixed(2)})`,
            }
          }, {
            x: groupAvgs[maxIndex].x,
            strokeDashArray: 0,
            borderColor: '#4821cb',
            label: {
              borderColor: '#4821cb',
              style: {
                color: '#fff',
                background: '#4821cb',
              },
              text: `MAX (${(groupAvgs[maxIndex].y - yields[maxIndex].y).toFixed(2)})`,
            }
          }];
          this.chart.options = {...this.chart.options, ...{ annotations: { xaxis: xaxis} } };
          this.chartDelta.options = {...this.chartDelta.options, ...{ annotations: { xaxis: xaxis} } };
          this.chartDelta.series[0].data = deltas;
        } else {
          this.chart.options = {...this.chart.options, ...{ annotations: { xaxis: [] } } };
        }

        this.previewChart = true
      } else {
        this.chart.series[0].data = []
        this.chart.series[1].data = []
        this.chart.options = {...this.chart.options, ...{ annotations: { xaxis: [] } } };

        this.chartDelta.series[0].data = deltas;
        this.chartDelta.options = {...this.chartDelta.options, ...{ annotations: { xaxis: xaxis} } };

      }
      this.chart.loading = false
    }
  }
}
</script>
<style lang="css">
.hot-cold tr {
  height: 36px !important;
}
.hot-cold td {
  height: 36px !important;
}
</style>
